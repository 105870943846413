<template>
    <v-dialog content-class="dialog--custom"
        v-model="activeComputed"
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
        width="480"
        persistent
    >
        <v-card class="text-center pa-1" color="login-popup">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="dialog-button"
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.verifyNotification.title') }}</v-card-title>

            <template v-if="!isSent">
                <v-card-subtitle>
                    {{ $t('auth.verifyEmailBeforeLogin.subtitle') }}
                </v-card-subtitle>
                <v-card-actions style="color: var(--v-text-base); font-weight: bold" class="justify-center headline mb-2">{{ $t('auth.verifyNotification.askText') }}</v-card-actions>
            </template>
            <template v-else>
                <v-card-actions style="color: var(--v-text-base); font-weight: bold" class="justify-center headline mb-2">{{ $t('auth.verifyNotification.successText') }}</v-card-actions>
            </template>

            <v-card-actions class="d-flex justify-center">
                <v-btn
                    block
                    class="button--dialog-custom"
                    :loading="isLoading"
                    :disabled="isDisabledSend"
                    @click="send"
                >
                    {{ $t('auth.verifyEmailBeforeLogin.sendButton') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import breaks from "@/mixins/breaks";
import {MODALS} from "@/configs/modals";

export default {
    props: ['active'],
    mixins: [breaks],
    data() {
        return {
            isLoading: false,
            isSent: false,
            isDisabledSend: false,
        }
    },
    computed: {
        MODALS() {
            return MODALS
        },
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: MODALS.VERIFY_EMAIL_SUCCESS});
            }
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        send() {
            this.isLoading = true;
            axios
                .get('/api/resend-verify-email')
                .then(() => {
                    this.isSent = true;
                    this.isDisabledSend = true;
                    setTimeout(() => {
                        this.isDisabledSend = false;
                    }, 5000)
                }).finally(() => {
                    this.isLoading = false;
                })
        }
    }
}
</script>

<style scoped>

</style>
