<template>
    <v-dialog
        content-class="modal-login"
        v-model="activeComputed"
        :width="smAndDown ? 400 : 800"
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
        :persistent="disabled_registration_mode"
    >
        <modal-appender-image
            :width="smAndDown ? 0 : 400"
            :parentActive="activeComputed"
        >
            <v-card class="flex-grow-1 text-center pa-1" :style="smAndDown ? 'height: 100%' : ''">
                <div class="d-flex justify-space-between ">
                    <v-card-title class="px-0 mt-2 d-block flex-grow-1">
                        <div class="modal-login__title">
                            {{ $t("auth.login.title") }}
                        </div>
                        <div class="modal-login__subtitle">
                            {{ $t("auth.login.subtitle") }}
                        </div>
                    </v-card-title>
                    <v-btn color='black' icon @click="activeComputed = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>

                <v-card-text>
                    <v-form ref="form" v-model="isFormValid" lazy-validation @submit.prevent>
                        <div v-if="google2fa">
                            <div v-if="qr_image" class="mb-2">
                                <div class="text-subtitle-1 mb-3" style="line-height: 1.2;">
                                    {{ $t('auth.register.fields.scanningQR') }}
                                    <span class="font-weight-bold">{{ secret }}</span>
                                </div>
                                <div v-html="qr_image"></div>
                            </div>
                            <div v-if="!qr_image" class="mb-4">{{ $t('auth.register.fields.enterOTP') }}</div>
                            <custom-text-field
                                class="input--white"
                                v-model="one_time_password"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required]"
                                :type="showPassword ? 'text' : 'password'"
                                :error="errors.hasOwnProperty('one_time_password')"
                                :error-messages="errors.hasOwnProperty('one_time_password') ? errors.one_time_password[0] : ''"
                                :label="$t('auth.register.fields.oneTimePassword')"
                                name="one_time_password"
                                outlined
                                @change="resetErrors"
                                @keyup.enter="submit"
                                @click:append="showPassword = !showPassword"
                            />
                        </div>

                        <div v-else>
                            <custom-text-field
                                v-if="comingFromAnotherSystem"
                                class="input--white"
                                v-model="data.passport"
                                :rules="passportRules"
                                :error="errors.hasOwnProperty('passport')"
                                :error-messages="errors.hasOwnProperty('passport') ? errors.passport[0] : ''"
                                :label="$t('auth.register.fields.passport')"
                                name="passport"
                                append-icon="mdi-passport"
                                outlined
                                @change="resetErrors"
                                @keyup.enter="submit"
                            />

                            <custom-text-field
                                class="input--white"
                                required
                                v-model="data.username"
                                :rules="[rules.required]"
                                :validate-on-blur="false"
                                :error="errors.hasOwnProperty('username')"
                                :error-messages="errors.hasOwnProperty('username') ? errors.username[0] : ''"
                                :label="$t('auth.login.fields.username')"
                                name="username"
                                outlined
                                @keyup.enter="submit"
                                @change="resetErrors"
                            />

                            <custom-text-field
                                v-if="comingFromAnotherSystem"
                                class="input--white"
                                v-model="data.email"
                                :rules="[rules.required]"
                                :validate-on-blur="false"
                                :error="errors.hasOwnProperty('email')"
                                :error-messages="errors.hasOwnProperty('email') ? errors.email[0] : ''"
                                :label="$t('auth.register.fields.email')"
                                name="email"
                                outlined
                                append-icon="mdi-email-outline"
                                @keyup.enter="submit"
                                @change="resetErrors"
                            />

                            <custom-text-field
                                v-if="!comingFromAnotherSystem || confirmUser"
                                class="input--white"
                                required
                                v-model="data.password"
                                :rules="[rules.required]"
                                :type="showPassword ? 'text' : 'password'"
                                :error="errors.hasOwnProperty('password')"
                                :error-messages="errors.hasOwnProperty('password') ? errors.password[0] : ''"
                                :label="$t('auth.login.fields.password')"
                                name="password"
                                outlined
                                @change="resetErrors"
                                @keyup.enter="submit"
                                @click:append="showPassword = !showPassword"
                            >
                                <template v-slot:append>
                                    <button type="button" class="login-modal__field-append" @click="showPassword = !showPassword">
                                        <v-icon size="16">
                                            {{showPassword ? 'mdi-eye' : 'mdi-eye-off'}}
                                        </v-icon>
                                        <span class="login-modal__field-append-text">
                                            {{ $t('auth.login.show') }}
                                        </span>
                                    </button>
                                </template>
                            </custom-text-field>

                            <div class="d-flex justify-end">
                                <button
                                    type="button"
                                    class="login-modal__forgot"
                                    @click="makeActiveWindow({ active: true, type: 'forgot'})"
                                >
                                    {{ $t('auth.login.forgotText') }}
                                </button>
                            </div>

                            <custom-text-field
                                v-if="confirmUser && comingFromAnotherSystem"
                                class="input--white"
                                v-model="data.password_confirmation"
                                :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required]"
                                :type="showPasswordConfirmation ? 'text' : 'password'"
                                :error="errors.hasOwnProperty('password_confirmation')"
                                :error-messages="errors.hasOwnProperty('password_confirmation') ? errors.password_confirmation[0] : ''"
                                :label="$t('auth.register.fields.password_confirmation')"
                                name="password_confirmation"
                                outlined
                                @change="resetErrors"
                                @keyup.enter="submit"
                                @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                            />

                            <v-switch
                                v-if="migrateUser"
                                class="mb-4"
                                hide-details
                                inset
                                dense
                                color="success"
                                :label="$t('labels.comingFromAnotherSystem')"
                                v-model="comingFromAnotherSystem"
                            />
                        </div>
                        <div class="mb-5"></div>
                        <v-btn
                            type="submit"
                            :loading="isLoading"
                            :disabled="isSignInDisabled"
                            block
                            color="primary"
                            elevation="0"
                            outlined
                            light
                            @click="submit"
                        >
                            {{ comingFromAnotherSystem && !confirmUserHash ? $t('auth.login.checkData') : comingFromAnotherSystem && confirmUserHash ? $t('auth.login.button') : $t('auth.login.button') }}
                        </v-btn>

                        <telegram-login
                            v-if="isHasTelegram"
                            class="mt-3"
                            script-name="login"
                            size="large"
                            radius="0"
                            :telegram-login="telegramUsername"
                            :closed="!activeComputed"
                            @callback="telegramCallback"
                        />

                        <div class="mt-2 red--text text-subtitle-1" v-if="error" v-text="error"></div>

<!--                        <div class="mt-5" v-if="!disabled_registration_mode">-->
<!--                            <div class="mb-5" v-if="isHasSocials">-->
<!--                                <div class="text-subtitle-2 font-weight-bold">-->
<!--                                    {{ $t('auth.orJoinNowWith') }}-->
<!--                                </div>-->

<!--                                <div class="d-flex align-center justify-center flex-wrap mt-2">-->
<!--                                    <template-->
<!--                                        v-for="(provider, i) in socialProviders"-->
<!--                                    >-->
<!--&lt;!&ndash;                                        <telegram-login&ndash;&gt;-->
<!--&lt;!&ndash;                                            v-if="provider.name === 'telegram' && auth_socials[provider.name]"&ndash;&gt;-->
<!--&lt;!&ndash;                                            script-name="login"&ndash;&gt;-->
<!--&lt;!&ndash;                                            :telegram-login="telegramUsername"&ndash;&gt;-->
<!--&lt;!&ndash;                                            :closed="!activeComputed"&ndash;&gt;-->
<!--&lt;!&ndash;                                            @callback="telegramCallback"&ndash;&gt;-->
<!--&lt;!&ndash;                                        />&ndash;&gt;-->
<!--                                        <v-btn-->
<!--                                            v-if="!(provider.name === 'telegram' && auth_socials[provider.name])"-->
<!--                                            :key="i"-->
<!--                                            class="mt-1"-->
<!--                                            :class="i + 1 === socialProviders.length ? '' : 'mr-1'"-->
<!--                                            x-small-->
<!--                                            fab-->
<!--                                            dark-->
<!--                                            elevation="false"-->
<!--                                            @click="authProvider(provider.name)"-->
<!--                                        >-->
<!--                                            <v-icon>{{ provider.icon }}</v-icon>-->
<!--                                        </v-btn>-->
<!--                                    </template>-->

<!--                                </div>-->
<!--                            </div>-->
<!--&lt;!&ndash;                            <v-btn&ndash;&gt;-->
<!--&lt;!&ndash;                                text link&ndash;&gt;-->
<!--&lt;!&ndash;                                class="router-link-exact-active router-link-active font-weight-bold"&ndash;&gt;-->
<!--&lt;!&ndash;                                style="height: auto !important;"&ndash;&gt;-->
<!--&lt;!&ndash;                                @click="makeActiveWindow({ active: true, type: 'forgot'})"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                                {{ $t('auth.login.bottomLink') }}&ndash;&gt;-->
<!--&lt;!&ndash;                            </v-btn>&ndash;&gt;-->
<!--                        </div>-->

                        <div class="login-modal__register-text" v-if="!disabled_registration_mode">
                            {{ $t('auth.login.underText.question') }}
                            <button
                                @click="makeActiveWindow({ active: true, type: 'register'})"
                            >
                                {{ $t('auth.login.underText.answer') }}
                            </button>
                        </div>

                    </v-form>
                </v-card-text>
            </v-card>
        </modal-appender-image>
    </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import breaks from "@/mixins/breaks";
import TelegramLogin from "@/components/user/TelegramLogin";
import Echo from "laravel-echo";
import _ from 'lodash';
import CustomTextField from "@/components/ui/CustomTextField.vue";
import ModalAppenderImage from "@/components/modals/ModalAppenderImage.vue";
import {MODALS} from "@/configs/modals";

export default {
    name: "Login",
    components: {ModalAppenderImage, CustomTextField, TelegramLogin},
    props: ['active'],
    mixins: [breaks],
    data() {
        return {
            showMigrateUser: false,
            comingFromAnotherSystem: false,
            confirmUser: false,
            isLoading: false,
            isSignInDisabled: false,
            isFormValid: true,
            confirmUserHash: null,
            socialProviders: [
                {
                    name: 'google',
                    icon: 'mdi-google'
                },
                {
                    name: 'facebook',
                    icon: 'mdi-facebook'
                },
                {
                    name: 'instagram',
                    icon: 'mdi-instagram'
                },
                {
                    name: 'twitter',
                    icon: 'mdi-twitter'
                },
                {
                    name: 'apple',
                    icon: 'mdi-apple'
                },
                {
                    name: 'reddit',
                    icon: 'mdi-reddit'
                },
                {
                    name: 'pinterest',
                    icon: 'mdi-pinterest'
                },
                {
                    name: 'twitch',
                    icon: 'mdi-twitch'
                },
                {
                    name: 'vkontakte',
                    icon: 'mdi-vk'
                },
                // {
                //     name: 'telegram',
                //     icon: 'mdi-telegram'
                // },
            ],
            data: {
                username: '',
                password: '',
                password_confirmation: '',
                passport: '',
                email: '',
                country: ''
            },

            qr_image: '',
            secret: '',
            google2fa: false,
            one_time_password: '',

            errors: {},
            error: null,
            showPasswordConfirmation: false,
            showPassword: false,
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t("form.required"),
                turkishPassport: (value) => {
                    const textError = "invalid"
                    if (!(/^[1-9]\d{10}$/).test(value)) {
                        return textError
                    }
                    let odds = 0
                    let evens = 0
                    let sumOf10 = 0
                    const digits = value.split('')
                    const d10 = Number(digits[9])
                    const d11 = Number(digits[10])

                    digits.forEach(function (d, index) {
                        d = Number(d);
                        if (index < 10) sumOf10 += d;
                        if (index < 9) {
                            if ((index + 1) % 2 === 0) {
                                evens += d;
                            } else {
                                odds += d;
                            }
                        }
                    })

                    if (sumOf10 % 10 !== d11 || ((odds * 7) + (evens * 9)) % 10 !== d10 || (odds * 8) % 10 !== d11) {
                        return textError
                    }

                    return true
                },
            },
            telegramUsername: process.env.MIX_TELEGRAM_BOT_USERNAME ?? '',
        }
    },
    computed: {
        redirectTo() {
            return this.$route.fullPath
        },
        isHasSocials() {
            return !_.isEmpty(this.auth_socials);
        },
        isHasTelegram() {
            return this.auth_socials?.telegram;
        },
        ...mapState({
            google2fa_enable: state => state.app.brandSettings.google2fa,
            google2fa_required: state => state.app.brandSettings.google2fa_required,
            redirect_after_login: state => state.app.brandSettings.redirect_after_login,
            disabled_registration_mode: state => state.app.brandSettings.disabled_registration_mode ?? false,
            migrateUser: state => state.app.brandSettings.migrate_user,
            auth_socials: state => state.app.brandSettings.auth_socials ?? {},
        }),
        isTurkishCountry() {
            return typeof this.data.country === "string" ? (
                this.data.country.toLowerCase() === 'turkey'
            ) : false
        },
        passportRules() {
            const rules = [this.rules.required]

            if (this.isTurkishCountry) {
                rules.push(this.rules.turkishPassport)
            }

            return rules
        },
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'login'});
            }
        }
    },
    watch: {
        comingFromAnotherSystem() {
            if (this.error) {
                this.error = null
            }
        },
    },
    methods: {
        async telegramCallback(data) {
            await axios.post('/api/telegram/login', data).then(
                async () => {
                    await this.$auth.fetch();
                    this.activeComputed = false;
                }
            )
        },
        authProvider(provider) {
            axios.get(`/api/authorize/${provider}/redirect`).then(res => {
                if (res.data.url) {
                    window.location.href = res.data.url
                }
            })
        },
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            setBalance: 'user/setBalance',
        }),
        setOnline() {
            this.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.MIX_PUSHER_APP_KEY,
                cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                encrypted: true,
                forceTLS: true,
                auth: {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token_default')}`,
                    },
                },
            })
            this.Echo.disconnect()
            this.Echo.join('online')
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.isSignInDisabled = true;
                if (this.comingFromAnotherSystem && !this.confirmUser) {
                    const data = {passport: this.data.passport, username: this.data.username, email: this.data.email}
                    axios.post('api/check-migrated-user', data).then(res => {
                        this.confirmUserHash = res.data.hash
                        this.data.country = res.data.country
                        this.confirmUser = true
                        this.isLoading = false
                        this.isSignInDisabled = false
                        this.error = null
                    }).catch(e => {
                        this.error = e.response.data
                        this.isLoading = false
                        this.isSignInDisabled = false
                    })
                } else if (this.confirmUser) {
                    const data = {
                        passport: this.data.passport,
                        username: this.data.username,
                        email: this.data.email,
                        user_id: this.confirmUserHash,
                        password: this.data.password,
                        password_confirmation: this.data.password_confirmation
                    }
                    axios.post('api/register-migrated-user', data).then(res => {
                        try {
                            const login_data = {
                                username: this.data.username,
                                password: this.data.password
                            }
                            this.$store.commit('user/setMigratedUser', true)
                            this.$auth.login({
                                data: login_data,
                                redirect: this.redirectTo,
                                staySignedIn: true
                            })
                            this.$store.commit('user/setLogin', true)
                            this.activeComputed = false;
                            this.isLoading = false
                            this.error = null
                        } catch (error) {
                            this.errors = error.response.data.errors;
                            this.isLoading = false;
                            this.isSignInDisabled = false;
                        }
                    }).catch(e => {
                        this.error = e.response.data
                        this.isLoading = false
                        this.isSignInDisabled = false
                    })
                } else if (this.google2fa) {
                    axios.post('/api/verify-google2fa', {
                        username: this.data.username,
                        password: this.data.password,
                        one_time_password: this.one_time_password
                    }).then(res => {
                        if (res.data === 'Accepted') {
                            this.$auth.login({
                                data: this.data,
                                redirect: this.redirectTo,
                                staySignedIn: true
                            }).catch(error => {
                                this.errors = error?.response?.data?.errors;
                                this.isLoading = false;
                                this.isSignInDisabled = false;

                                if (this.errors.verification) {
                                    this.makeActiveWindow({
                                        type: MODALS.VERIFY_EMAIL_BEFORE_LOGIN,
                                        active: true,
                                    })
                                }
                            })
                            this.$store.commit('user/setLogin', true)
                            this.activeComputed = false;
                        }
                    }).catch(error => {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                        this.isSignInDisabled = false;
                    })
                } else if (this.google2fa_enable && !this.google2fa) {
                    try {
                        await axios.post('/api/google2fa-login', this.data).then(res => {
                            if (res.data) {
                                this.google2fa = true
                                this.qr_image = res.data.qr_image ? res.data.qr_image : null
                                this.secret = res.data.secret ? res.data.secret : null
                                this.isLoading = false;
                                this.isSignInDisabled = false;
                            } else {
                                this.$auth.login({
                                    data: this.data,
                                    redirect: this.redirectTo,
                                    staySignedIn: true
                                }).then(() => {
                                    this.$store.commit('user/setLogin', true)
                                    this.setBalance({
                                        amount: this.$auth.user().amount,
                                        amount_bonus: this.$auth.user().amount_bonus
                                    });
                                    this.$refs.form.reset()
                                    this.activeComputed = false;
                                    this.isSignInDisabled = false;
                                    this.isLoading = false;
                                }).catch((error) => {
                                    this.isLoading = false;
                                    this.isSignInDisabled = false;
                                    if (error.response?.data?.errors) {
                                        this.errors = error.response.data.errors;
                                    }
                                    if (error.response?.data?.message) {
                                        this.error = error.response.data.message
                                    }

                                    if (this.errors.verification) {
                                        this.makeActiveWindow({
                                            type: MODALS.VERIFY_EMAIL_BEFORE_LOGIN,
                                            active: true,
                                        })
                                    }
                                });
                                this.$store.commit('user/setLogin', true)
                                // this.activeComputed = false;
                            }
                        })
                    } catch (error) {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                        this.isSignInDisabled = false;
                    }
                } else {
                    try {
                        await this.$auth.login({
                            data: this.data,
                            redirect: this.redirectTo,
                            staySignedIn: true
                        }).then(() => {
                            this.$store.commit('user/setLogin', true)
                            this.setBalance({
                                amount: this.$auth.user().amount,
                                amount_bonus: this.$auth.user().amount_bonus
                            });
                            this.$refs.form.reset()
                            this.activeComputed = false;
                            this.isSignInDisabled = false;
                            this.isLoading = false;
                        }).catch((error) => {
                            this.isLoading = false;
                            this.isSignInDisabled = false;
                            if (error.response.data.errors) {
                                this.errors = error.response.data.errors;
                            }
                            if (error.response.data.message) {
                                this.error = error.response.data.message
                            }

                            if (this.errors.verification) {
                                this.makeActiveWindow({
                                    type: MODALS.VERIFY_EMAIL_BEFORE_LOGIN,
                                    active: true,
                                })
                            }
                        })
                    } catch (error) {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                        this.isSignInDisabled = false;

                        if (this.errors.verification) {
                            this.makeActiveWindow({
                                type: MODALS.VERIFY_EMAIL_BEFORE_LOGIN,
                                active: true,
                            })
                        }
                    }
                }
            }
        },
        resetErrors() {
            this.errors = {};
        }
    }
}
</script>

<style lang="scss">
.v-application .v-dialog__content .v-dialog.modal-login {
    //background-color: transparent !important;

    .v-card {
        background-color: white;
    }
    .v-card__title {
        color: var(--v-primary-base);
        justify-content: center;
    }
    .modal-login__title {
        padding-bottom: 4px;
        color: var(--v-primary-base);
        text-align: center;
        font-size: 24px;
        line-height: 28px;
    }
    .modal-login__subtitle {
        color: var(--v-background-base);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .5px;
        line-height: 20px;
    }

    .v-card__text {
        padding: 4px 20px;
    }

    .custom-text-field {
        margin-bottom: 0;
    }

    .custom-text-field__messages {
        color: black;
        font-size: 16px;

        .custom-text-field__error {
            font-size: 12px;
        }
    }

    .v-input__slot {
        margin-top: 4px !important;
    }

    .login-modal__field-append {
        margin-top: 4px;
        display: flex;
        align-items: center;
    }

    .login-modal__field-append-text {
        font-size: 14px;
        padding-left: 4px;
    }

    .login-modal__forgot {
        font-size: 11px;
        color: #abadac;
    }

    .login-modal__register-text {
        margin-top: 16px;
        font-size: 11px;
        color: #abadac;

        button {
            color: var(--v-primary-base);
            text-decoration: underline;
        }
    }

}
</style>
