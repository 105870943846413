<template>
    <v-card>
        <!-- CURRENCY & NETWORK SELECTS -->
        <v-card-text>
            <v-row dense>
                <v-col
                    cols="12"
                    :sm="hasNetwork ? 6 : 12"
                >
                    <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                        {{ $t('labels.currency') }}:
                    </div>
                    <custom-select
                        class="select--white full-width"
                        :value="currentCryptoCurrency"
                        :items="currenciesCrypto.map(i => i.name)"
                        hide-details
                        outlined
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        @input="onChangeCurrency"
                    >
                        <template
                            #prepend-inner
                            v-if="$auth.user()['cryptoBalance']"
                        >
                            <svg
                                v-html="currentCryptoCurrencyIconHtml"
                                class="cc-icon"
                                style="margin-top: 9px;"
                            />
                        </template>

                        <template #selection>
                            {{ currentCryptoCurrency }} - {{ amountCryptoAsFiatString(currentCryptoCurrency) }}
                        </template>

                        <template #item="scope">
                            <svg
                                v-html="getIconHtml(scope.item)"
                                class="cc-icon"
                            />

                            {{ scope.item }} - {{ amountCryptoAsFiatString(scope.item) }}
                        </template>
                    </custom-select>
                </v-col>
                <v-col
                    v-if="hasNetwork"
                    cols="12" sm="6"
                >
                    <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                        {{ $t('labels.network') }}:
                    </div>
                    <custom-select
                        class="select--white full-width"
                        v-model="selectedCryptoNetwork"
                        :items="networksCrypto"
                        outlined
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        hide-details return-object
                    >
                        <template #item="scope">
                            {{ scope.item.text }}
                        </template>
                    </custom-select>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- WITHDRAWAL FORM -->
        <v-card-text class="pb-5">
            <v-form
                class="crypto_withdraw_form"
                ref="form"
                v-model="isFormValid"
                lazy-validation
                @submit.prevent
            >
                <v-row dense>
                    <v-col cols="12">
                        <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                            {{ $t('labels.cryptoAddress') }}:
                        </div>
                        <custom-text-field
                            class="input--white"
                            v-model="withdrawal.address"
                            :title="withdrawal.address"
                            :rules="[rules.required]"
                            outlined
                            @change="resetErrors"
                            @keyup.enter="submit"
                        >
                            <template #label>
                                <svg
                                    v-html="currentCryptoCurrencyIconHtml"
                                    class="cc-icon mr-1 mb-n1"
                                />
                                {{ currentCryptoCurrency }} {{ $t('common.depositAddress') }}
                            </template>
                        </custom-text-field>
                    </v-col>
                </v-row>

                <v-row
                    v-if="currentCryptoCurrency === 'XRP'"
                    dense
                >
                    <v-col cols="12">
                        <div  class="black--text font-weight-bold text-left" style="font-size: 16px;">
                            {{ $t('labels.cryptoAddressMemo') }}:
                        </div>
                        <custom-text-field
                            class="input--white"
                            v-model="withdrawal.memo"
                            :title="withdrawal.memo"
                            :rules="[rules.required]"
                            outlined
                            @change="resetErrors"
                            @keyup.enter="submit"
                        >
                            <template #label>
                                <svg
                                    v-html="currentCryptoCurrencyIconHtml"
                                    class="cc-icon mr-1 mb-n1"
                                />

                                {{ currentCryptoCurrency }} {{ $t('common.depositMemo') }}
                            </template>
                        </custom-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                            {{ $t('labels.amount') }}:
                            <span v-if="withdrawal.amount" class="font-weight-regular error--text">
                                {{ errorAmount }}
                            </span>
                        </div>
                        <custom-text-field
                            class="input--white"
                            v-model="withdrawal.amount"
                            :title="withdrawal.amount"
                            :rules="[rules.required, amountRulesValue]"
                            outlined
                            no-error-icon
                            :error="!!errorAmount"
                            :error-messages="errorAmount"
                            @focus="errorAmount = ''"
                            @change="resetErrors"
                            @keyup.enter="submit"
                        >
                            <template #label>
                                <svg
                                    v-html="currentCryptoCurrencyIconHtml"
                                    class="cc-icon mr-1 mb-n1"
                                />

                                {{ $t('form.amount') }}
                            </template>
                            <template #append>
                                <svg
                                    v-html="currentCryptoCurrencyIconHtml"
                                    class="cc-icon mt-1 mr-2"
                                />

                                <v-btn
                                    dark
                                    class="primary mr-n4 mt-n2"
                                    :rounded="false"
                                    @click="withdrawal.amount = withdrawMaxAmount()"
                                >
                                    {{ $t('form.max') }}
                                </v-btn>
                            </template>
                        </custom-text-field>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>

        <v-card-text class="pt-2">
            <v-btn
                :class="successRequest ? 'white--text' : ''"
                :color="successRequest ? 'success' : 'primary'"
                :loading="isLoading"
                x-large block
                class="mb-4"
                @click="submit"
            >
                <v-icon
                    v-if="successRequest"
                    left
                >
                    mdi-check
                </v-icon>

                {{ $t('form.withdrawal') }}
            </v-btn>

            <span
                v-if="error"
                class="red--text mt-2 text-subtitle-1"
            >
                {{ error }}
            </span>

            <div v-if="errorsArr.length">
                <span class="red--text text-subtitle-1 mt-2 d-block">
                    {{ $t('dashboard.sections.withdrawal.error.reservedBonus') }}
                </span>

                <span
                    v-for="(e, eIndex) in errorsArr"
                    :key="`formError${eIndex}`"
                    class="red--text text-subtitle-1 mt-2 d-block"
                >
                    <template v-for="eType in [
                        {name: 'name',          t: $t('dashboard.sections.withdrawal.error.bonusName')},
                        {name: 'promocode',     t: $t('dashboard.sections.withdrawal.error.bonusPromo')},
                        {name: 'amount_wins',   t: $t('dashboard.sections.withdrawal.error.reservedBonusAmount')},
                        {name: 'data',          t: $t('dashboard.sections.withdrawal.error.reservedUntil')},
                    ]">
                        <span
                            v-if="e[eType.name]"
                            class="d-block"
                        >
                            {{ e[eType.t] }}: {{ e[eType.name] }}
                        </span>
                    </template>
                </span>
            </div>

            <template
                v-for="otherError in [
                    {value: serverError,    t: $t('dashboard.sections.withdrawal.error.serverError')},
                    {value: lowBalance,     t: $t('dashboard.sections.withdrawal.error.lowBalance')},
                    {value: alreadyExists,  t: $t('dashboard.sections.withdrawal.error.alreadyExist')},
                ]"
            >
                <span
                    v-if="otherError.value"
                    class="red--text text-subtitle-1 mt-2"
                >
                    {{ otherError.t }}
                </span>
            </template>

            <div class="mt-2 text-left text-subtitle-2 black--text">
                <span class="primary--text font-weight-bold">
                    {{ $t('cryptoDescription.notice') }}
                </span>
                <span class="black--text">
                    {{ $t('form.min') }} {{ withdrawal.min }}
                    <svg
                        v-html="currentCryptoCurrencyIconHtml"
                        class="cc-icon"
                    />
                    . {{ $t('form.commissionWithdrawal') }} {{ withdrawal.commission }}
                    <svg
                        v-html="currentCryptoCurrencyIconHtml"
                        class="cc-icon"
                    />
                    {{ $t('form.commissionWithdrawalTitle') }}.
                </span>
                <br/>
                <span class="black--text">
                    {{ $tc('cryptoDescription.label', parseInt(confirmationCount), {currency: currentCryptoCurrency, confirmation: confirmationCount}) }}
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex"
import TheWalletTabMixin from "@/components/modals/TheWallet/TheWalletTabMixin"
import {cancelBonus} from "@/helpers/confirm-alert";
import Bonus from "@/models/Bonus";
import CustomSelect from "@/components/ui/CustomSelect.vue";
import CustomTextField from "@/components/ui/CustomTextField.vue";

export default {
    name: "TheWalletWithdraw",
    components: {CustomTextField, CustomSelect},
    mixins: [
        TheWalletTabMixin,
    ],
    data() {
        return {
            withdrawal: {
                address: null,
                memo: null,
                amount: null,
                min: null,
                max: null,
                commission: null
            },

            cryptoWithdrawal: {},

            isFormValid: true,
            isLoading: false,
            errors: {},
            error: '',
            errorsArr: [],
            errorAmount: '',
            lowBalance: false,
            bonusReserved: false,
            alreadyExists: false,
            serverError: false,
            successRequest: false,
        }
    },
    computed: {
        ...mapState({
            verifyEmail: state => state.app.brandSettings.verify_email,
        }),

        hasNetwork() {
            return !!(this.networksCrypto?.length);
        }
    },
    watch: {
        currentCryptoCurrency() {
            this.lowBalance = false
            this.withdrawal.amount = null
            this.withdrawal.address = null
            this.withdrawal.memo = null
            this.$refs.form.resetValidation()
            this.getWithdrawalData()
        }
    },
    created() {
        this.getCryptoWithdrawal(this.defaultCryptoCurrency)
    },
    mounted() {
        this.selectedCryptoNetwork = this.getCryptoCurrencyNetwork(this.currentCryptoCurrency)?.[0];
    },
    methods: {
        ...mapActions('app', [
            'makeActiveWindow',
        ]),

        withdrawMaxAmount() {
            try {
                const amount = parseFloat(
                    this.userCryptoBalances.find(
                        b => b.name === this.currentCryptoCurrency
                    ).amount_crypto
                )
                return amount > 0 ? amount : 0.00
            } catch (e) {
                console.log(e)
                return 0.00
            }
        },

        /**
         * Change currency handler
         * @param currency
         */
        onChangeCurrency(currency) {
            Promise
                .resolve(this.selectedCryptoCurrency = currency)
                .then(() => this.selectedCryptoNetwork = this.networksCrypto ? this.networksCrypto[0] : null)
                .catch(console.error)
        },

        getCryptoWithdrawal() {
            axios
                .get('/api/get_crypto_withdrawal')
                .then(res => {
                    this.cryptoWithdrawal = res.data.data
                    this.getWithdrawalData()
                })
        },

        getWithdrawalData() {
            let currency = this.cryptoWithdrawal.currencies?.find(v => v.currency === this.currentCryptoCurrency)
            this.withdrawal.min = currency?.min || 0
            this.withdrawal.max = currency?.max || 0
            this.withdrawal.commission = currency?.commission || 0
        },

        setDefaultCryptoBalance(currency) {
            this.currentCryptoCurrency = currency
            this.getWithdrawalData()

            axios
                .post('/api/set-default-crypto-currencies', {
                    currency: currency,
                })
                .then(() => {
                    this.$auth.fetch()
                })
        },

        amountRules() {
            return this.amountRulesValue(this.withdrawal.amount);
        },
        amountRulesValue(value) {
            let currency = this.cryptoWithdrawal?.currencies?.find(v => v.currency === this.currentCryptoCurrency)
            let min = Number(currency?.min || 0)
            let max = Math.min(Number(this.withdrawMaxAmount()), Number(currency?.max || Infinity))
            var rgx = /^[0-9]*\.?[0-9]*$/;

            if (!value) {
                this.errorAmount = ''
                return true
            }
            if (Number(this.withdrawMaxAmount() === 0)) {
                this.errorAmount = `${this.$t('form.notEnoughBalanceError')}`
                return false
            }
            if (!String(value).match(rgx)) {
                this.errorAmount = `${this.$t('form.onlyDigitsAndDotError')}`
                return false
            }
            if (value < min) {
                this.errorAmount = `${this.$t('form.minAmountError')} ${min}.`
                return false
            } else if (value > max) {
                this.errorAmount = `${this.$t('form.maxAmountError')} ${max}.`
                return false
            }
            this.errorAmount = ''
            return true
        },

        async submit() {
            if (this.$auth.user().active_bonus) {
                let res = await cancelBonus();
                if (!res) {
                    return;
                } else {
                    await new Bonus(this.$auth.user().active_bonus).delete();
                }
            }

            if (
                this.verifyEmail
                &&
                !this.$auth.user()['email_verified_at']
            ) {
                await this.makeActiveWindow({ active: true, type: 'verifyEmail'})
                return false
            }

            if (this.$refs.form.validate() && this.amountRules()) {
                this.isLoading = true

                axios
                    .post("/api/user-dashboard/withdrawal-crypto", {
                        "is_crypto"         : true,
                        "payment_method_id" : this.cryptoWithdrawal.id,
                        "address"           : this.withdrawal.address,
                        "amount"            : this.withdrawal.amount,
                        "currency"          : this.currentCryptoCurrency,
                        "network"           : this.selectedCryptoNetwork?.value || null,
                        "memo"              : this.withdrawal.memo
                    })
                    .then(() => {
                        this.isLoading = false
                        this.successRequest = true
                        this.lowBalance = false
                        this.withdrawal.amount = null
                        this.withdrawal.address = null
                        this.withdrawal.memo = null
                        this.$refs.form.resetValidation()

                        setTimeout(() => {
                            this.successRequest = false
                        }, 3000)
                    })
                    .catch(e => {
                        console.error('### TheWalletWithdraw @ submit:', e)

                        this.isLoading = false

                        this.serverError = 500 === e.response?.status

                        this.error = e.response?.data?.message
                            ? this.$t(e.response.data.message)
                            : e.response?.data || ""

                        if (422 === e.response?.status) {
                            this.lowBalance = "LowBalance" === e.response.data
                            this.alreadyExists = "WithdrawalAlreadyExists" === e.response.data
                            this.errorsArr = Array.isArray(e.response.data) ? e.response.data : []
                        }
                    })
            }
        },

        resetErrors() {
            this.errors = {}
            this.error = ''
        },
    },
}
</script>

<style lang="scss" scoped>
.cc-icon {
    width: 20px;
    height: 20px;
    margin: 0 6px 0 0;
}
.crypto_withdraw_form .v-input__append-outer {
    margin-top: 0 !important;
}
</style>
