import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Routes
import PagesRoutes from './pages.routes';
import PromotionsRoutes from './promotions.routes';
import AuthRoutes from './auth.rotes';
import ErrorsRoutes from './errors.routes';
import UtilityRoutes from './utility.routes';
import UserRoutes from './user.routes';

Vue.use(Router)

export const routes = [
    ...PagesRoutes,
    ...PromotionsRoutes,
    ...AuthRoutes,
    ...ErrorsRoutes,
    ...UtilityRoutes,
    ...UserRoutes,
    {
        path: '/blank',
        name: 'blank',
        component: () => import(/* webpackChunkName: "blank" */ '@/pages/BlankPage.vue')
    },
    {
        path: '/dev/test',
        name: 'dev.test',
        meta: {
            layout: 'simple',
        },
        component: () => import(/* webpackChunkName: "blank" */ '@/pages/_dev/TestPage.vue')
    },
    {
        path: '*',
        redirect: '/error/not-found'
    }
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL || '/',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition
        if (to.name === 'casino.category') return null
        return {x: 0, y: 0}
    },
    routes
})

/**
 * Before each route update
 */
router.beforeEach(async (to, from, next) => {

    let isCanNext = true;

    const {isValid, isDirty, requestQueries} = await store.dispatch('handleQueries', to.query);
    isCanNext = isValid;

    if (_.hasIn(to, 'meta.disabled')) {
        const payload = to.meta.disabled.reduce((acc, item) => ({...acc, [item]: false}), {})
        store.dispatch('app/setLayout', payload);
    }

    const result = isCanNext
        && isDirty
            ? {
                ...to,
                query: requestQueries,
            }
            : true;

    return next(result);
})

// router.beforeResolve((to, from, next) => {
//     if (window.innerWidth <= 600 && to.meta.layout === 'dashboard') {
//         store.dispatch('app/makeActiveWindow', {type: 'userDashboardMobile', active: true})
//         store.dispatch('app/updateUserDashboardMobileDialog', {component: to.matched[0].components.default, title: to.meta.title, route: to})
//         return next(false);
//     } else {
//         return next()
//     }
// })

/**
 * After each route update
 */
// router.afterEach((to, from) => {
// })

export default router
