<template>
    <v-menu
        nudge-width="200"
        nudge-bottom="32"
        nudge-left="100"
        dark
    >
        <template v-slot:activator="props">
            <slot name="activator" v-bind="props"/>
        </template>
        <template>
            <v-card class="pa-4">
                <div class="text-subtitle-2 mb-2 text-uppercase">
                    {{ bonus.name }}
                </div>
                <div class="d-flex align-center" v-if="!isFreespinIsRealMoney">
                    <v-progress-linear
                        :value="progressValue"
                        height="25"
                    >
                        <strong>{{ textValue }}</strong>
                    </v-progress-linear>
                </div>
            </v-card>
        </template>
    </v-menu>
</template>
<script>

import breaks from "@/mixins/breaks";
import bonuses from "@/mixins/bonuses";
import {mapState} from "vuex";
export default {
    name: 'BonusModalDeposit',
    mixins: [breaks, bonuses],
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data: () => {
        return {
        }
    },
    computed: {
        isActive: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val);
            }
        },
    },
}
</script>
