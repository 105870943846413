<template>
    <v-dialog
        v-model="activeComputed"
        width="480"
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
    >
        <v-card class="text-center pa-1" color="login-popup">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color=""
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.forgot.title') }}</v-card-title>
<!--            <v-card-subtitle>-->
<!--                {{ $t('auth.forgot.subtitle') }}-->
<!--            </v-card-subtitle>-->

            <v-card-text>
                <v-form ref="form" v-model="isFormValid" lazy-validation @submit.prevent="submit">
                    <custom-text-field
                        v-model="email"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        :error="error"
                        :error-messages="errorMessages"
                        :label="$t('auth.forgot.fields.email')"
                        :placeholder="$t('auth.forgot.fields.email')"
                        persistent-placeholder
                        name="email"
                        :disabled="isLoading"
                        outlined
                        @keyup.enter="submit"
                        @input="resetErrors"
                    ></custom-text-field>

                    <v-btn
                        :loading="isLoading"
                        block
                        class="button--gray"
                        @click="submit"
                    >{{ $t('auth.forgot.button') }}
                    </v-btn>

                    <div class="text-center mt-6">
                        <v-btn
                            text link
                            @click="makeActiveWindow({ active: true, type: 'login'})"
                            class="router-link-exact-active router-link-active font-weight-bold"
                            style="height: auto !important;"
                        >
                            {{ $t('auth.forgot.underText.link') }}
                        </v-btn>
                    </div>
                </v-form>
                <v-card-subtitle v-if="sendToEmail" class="green--text mt-2">
<!--                    {{ $t('auth.forgot.successMessage') }}-->
                    <v-dialog
                        v-model="sendToEmail"
                        width="480"
                        :fullscreen="smAndDown"
                        :hide-overlay="smAndDown"
                    >
                        <v-card class="text-center pa-1" color="login-popup">
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color=""
                                    icon
                                    @click="sendToEmail = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-actions>
                            <v-card-text>
                                {{ $t('auth.forgot.message') }}
                            </v-card-text>
                            <br/>
                        </v-card>
                    </v-dialog>
                </v-card-subtitle>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import breaks from "@/mixins/breaks";
import CustomTextField from "@/components/ui/CustomTextField.vue";
import {MODALS} from "@/configs/modals";

export default {
    components: {CustomTextField},
    props: ['active'],
    mixins: [breaks],
    data() {
        return {
            // reset button
            isLoading: false,

            // form
            isFormValid: true,
            email: '',
            sendToEmail: false,

            // form error
            error: false,
            errorMessages: '',

            // input rules
            rules: {
                required: (value) => (value && Boolean(value)) || 'Required'
            }
        }
    },
    computed: {
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'forgot'});
            }
        }
    },
    watch: {
        sendToEmail(val) {
            if(val) {
                this.activeComputed = false;
            }
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        submit(e) {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                axios.post('/api/forgot-password', {email: this.email}).then(res => {
                    this.sendToEmail = true
                    this.isLoading = false;
                    this.activeComputed = false;
                }).catch(e => {
                    this.isLoading = false;
                    this.errorMessages = e.response.data
                })
            }
        },
        resetEmail(email, password) {

        },
        resetErrors() {
            this.error = false
            this.errorMessages = ''
        }
    }
}
</script>

<style>

</style>
