
export default {
    currencies: [
        {
            name: 'USD',
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: '&#36;'
        },
        {
            name: 'EUR',
            icon: '<use xlink:href="#icon-currency-eur"></use>',
            symbol: '&#8364;'
        },
        {
            name: 'TRY',
            icon: '<use xlink:href="#icon-currency-try"></use>',
            symbol: '&#8378;'
        },
        {
            name: 'JPY',
            icon: '<use xlink:href="#icon-currency-jpy"></use>',
            symbol: '&#165;'
        },
        {
            name: 'BRL',
            icon: '<use xlink:href="#icon-currency-brl"></use>',
            symbol: 'BRL'
        },
        {
            name: 'CAD',
            icon: '<use xlink:href="#icon-currency-cad"></use>',
            symbol: '&#36;'
        },
        {
            name: 'CNY',
            icon: '<use xlink:href="#icon-currency-cny"></use>',
            symbol: '&#20803;'
        },
        {
            name: 'DKK',
            icon: '<use xlink:href="#icon-currency-dkk"></use>',
            symbol: 'DKK'
        },
        {
            name: 'IDR',
            icon: '<use xlink:href="#icon-currency-idr"></use>',
            symbol: 'IDR'
        },
        {
            name: 'INR',
            icon: '<use xlink:href="#icon-currency-inr"></use>',
            symbol: '&#8377;'
        },
        {
            name: 'KRW',
            icon: '<use xlink:href="#icon-currency-krw"></use>',
            symbol: '&#8361;'
        },
        {
            name: 'MXN',
            icon: '<use xlink:href="#icon-currency-mxn"></use>',
            symbol: '&#36;'
        },
        {
            name: 'PHP',
            icon: '<use xlink:href="#icon-currency-php"></use>',
            symbol: '&#8369;'
        },
        {
            name: 'RUB',
            icon: '<use xlink:href="#icon-currency-rub"></use>',
            symbol: '&#8381;'
        },
        {
            name: "PLN",
            icon: '<use xlink:href="#icon-currency-pln"></use>',
            symbol: 'Z&#322;'
        },
        {
            name: "CLP",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'CLP'
        },
        {
            name: "AED",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'AED'
        },
        {
            name: "AUD",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'AUD'
        },
        {
            name: "BGN",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'BGN'
        },
        {
            name: "CZK",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'CZK'
        },
        {
            name: "GBP",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'GBP'
        },
        {
            name: "NOK",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'NOK'
        },
        {
            name: "NZD",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'NZD'
        },
        {
            name: "SEK",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'SEK'
        },
        {
            name: "ZAR",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'ZAR'
        },
        {
            name: "COP",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'COP'
        },
        {
            name: "CRC",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'CRC'
        },
        {
            name: "PEN",
            icon: '<use xlink:href="#icon-currency-usd"></use>',
            symbol: 'PEN'
        }

    ],
    crypto_currencies: [
        {
            name: 'BTC',
            icon: '<use xlink:href="#icon-currency-btc"></use>',
            confirmation: "0/1",
        },
        {
            name: 'ETH',
            icon: '<use xlink:href="#icon-currency-eth"></use>',
            confirmation: 10,
        },
        {
            name: 'LTC',
            icon: '<use xlink:href="#icon-currency-ltc"></use>',
            confirmation: 6,
        },
        {
            name: 'DOGE',
            icon: '<use xlink:href="#icon-currency-doge"></use>',
            confirmation: 6,
        },
        {
            name: 'BCH',
            icon: '<use xlink:href="#icon-currency-bch"></use>',
            confirmation: "0/6",
        },
        {
            name: 'XRP',
            icon: '<use xlink:href="#icon-currency-xrp"></use>',
            confirmation: 1,
        },
        {
            name: 'TRX',
            icon: '<use xlink:href="#icon-currency-trx"></use>',
            confirmation: 19,
        },
        {
            name: 'TRON',
            icon: '<use xlink:href="#icon-currency-trx"></use>',
            confirmation: 19,
        },
        {
            name: 'EOS',
            icon: '<use xlink:href="#icon-currency-eos"></use>'
        },
        {
            name: 'BNB',
            icon: '<use xlink:href="#icon-currency-bnb"></use>',
            confirmation: 25,
        },
        {
            name: 'USDT',
            icon: '<use xlink:href="#icon-currency-usdt"></use>',
            confirmation: [
                {
                    network: 'TRC20',
                    confirmation: 19,
                },
                {
                    network: 'ERC20',
                    confirmation: 10,
                },
            ]
        },
        {
            name: 'USDC',
            icon: '<use xlink:href="#icon-currency-usdc"></use>',
            confirmation: 10
        },
        {
            name: 'DOT',
            icon: '<use xlink:href="#icon-currency-dot"></use>'
        },
        {
            name: 'LINK',
            icon: '<use xlink:href="#icon-currency-link"></use>'
        },
        {
            name: 'XLM',
            icon: '<use xlink:href="#icon-currency-xlm"></use>'
        },
        {
            name: 'NUMMUS',
            icon: '<use xlink:href="#icon-currency-nummus"></use>'
        },
    ]
}
