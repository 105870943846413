import Vue from 'vue'
import Vuex from 'vuex'

import onChangeLangPlugin from "@/store/plugins/onChangeLangPlugin";
import onChangePlatformPlugin from "@/store/plugins/onChangePlatformPlugin";

// Modules
import AppModule from './modules/app'
import GamesModule from './modules/games'
import TranslatableModule from './modules/translatable'
import UserModule from "./modules/user";
import {MODALS, WALLET_TABS} from "@/configs/modals";

const state = {
    queries: {},
}

const actions = {
    onChangeLang: () => {},
    init: async ({dispatch}) => {
        // await dispatch('app/_init');
        // await dispatch('user/_init');
        // await dispatch('translatable/_init');
        await dispatch('games/_init');

    },
    handleQueries: async ({state, commit, dispatch}, payload = {}) => {
        const requestQueries = structuredClone(payload);

        const stateQueries = {};
        let isValid = true;
        let isDirty = false;

        Object.entries(requestQueries).forEach(([key, value]) => {
            if (key === 'promocode') {
                stateQueries[key] = value;

                delete requestQueries[key];
                isDirty = true;
            }

            if (key === 'modal') {
                stateQueries[key] = value;

                Vue.prototype.$auth.load().then(() => {
                    const user = Vue.prototype.$auth.user();
                    let type = value;

                    if (user) {
                        if (type === MODALS.REGISTER) {
                            type = WALLET_TABS.DEPOSIT;
                        }
                        type = Object.values(WALLET_TABS).includes(type) ? MODALS.WALLET : type;

                    } else {
                        if (Object.values(WALLET_TABS).includes(type)) {
                            type = MODALS.REGISTER;
                        }
                    }

                    if (type) {
                        dispatch(
                            'app/makeActiveWindow',
                            {
                                type,
                                active: value,
                            },
                        );
                    }

                })

                delete requestQueries[key];
                isDirty = true;
            }
        })

        state.queries = {...state.queries, ...stateQueries};

        return {
            isValid,
            isDirty,
            requestQueries,
    };
    }
}

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
    state,
    actions,
  modules: {
    app: AppModule,
    games: GamesModule,
    translatable: TranslatableModule,
    user: UserModule,
  },
    plugins: [onChangeLangPlugin, onChangePlatformPlugin]
})

// store.dispatch('_init');

export default store
