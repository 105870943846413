<template>
    <v-dialog  content-class="dialog--custom"
        v-model="activeComputed"
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
        width="480"
        persistent
    >
        <v-card class="text-center pa-1" color="login-popup">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="dialog-button"
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.verifyNotificationSuccess.title') }}</v-card-title>
            <v-card-subtitle>
                {{ $t('auth.verifyEmailNotificationSuccess.subtitle') }}
            </v-card-subtitle>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    block
                    class="button--dialog-custom"
                    @click="openLoginModal"
                >
                    {{ $t('auth.verifyEmailNotificationSuccess.login') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import breaks from "@/mixins/breaks";
import {MODALS} from "@/configs/modals";

export default {
    props: ['active'],
    mixins: [breaks],
    data() {
        return {

        }
    },
    computed: {
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: MODALS.VERIFY_EMAIL_SUCCESS});
            }
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        openLoginModal() {
            this.makeActiveWindow({active: true, type: MODALS.LOGIN});
        }
    },
}
</script>
