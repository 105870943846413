<template>
    <v-footer class="footer d-block mt-auto mb-lg-0" :class="smAndDown ? 'footer__mobile' : ''" dark padless id="app-footer">
        <div ref="footer-content" class="footer__wrapper" v-html="smAndDown ? mobileFooter : desktopFooter"></div>

        <toolbar-language
            ref="language"
            :cssClass="'mt-4 widget-language-component'"
            class="footer-lang-component"
        />
    </v-footer>
</template>

<script>
import Swiper from "swiper";
import {mapState} from "vuex"

import breaks from "@/mixins/breaks"
import ToolbarLanguage from "@/components/toolbar/ToolbarLanguage.vue";

export default {
    name: "AppFooter",
    components: {ToolbarLanguage},
    mixins: [breaks],
    methods: {
        footerLoad() {
                this.tmp++;
                this.initLangWidget()
            this.$nextTick(()=>{
                new Swiper('.footer-swiper', {
                    speed: 1400,
                    loop: true,
                    slidesPerView: 'auto',
                    watchSlidesVisibility: true,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: false,
                        waitForTransition: false,
                    },
                })
            })
        },
        handle(e) {
            // vue router
            let a = e.target.closest('a');
            if (a) {
                let link = a?.getAttribute('href');
                if (link && link[0] === '/') {
                    e.preventDefault();
                    if(link === this.$route.path){
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                    } else {
                        this.$router.push(link);
                    }
                }

                // accordion
                else if (a.classList.contains('footer-accordion__title')) {
                    e.preventDefault();
                    let item = a.closest('.footer-accordion__item')
                    if (item) {
                        item.classList.toggle('footer-accordion__item--active');
                        if (item.classList.contains('footer-accordion__item--active')) {
                            setTimeout(()=>{
                                if (item.getBoundingClientRect().bottom >= document.documentElement.clientHeight + 30){
                                    item.scrollIntoView({block: 'end', behavior: 'smooth'});
                                }
                            }, 200)
                        }
                    }
                } else if (a.classList.contains('footer-link__up')) {
                    e.preventDefault()
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                }
            }


        },
        removeActiveClass() {
            const footerRef = this.$refs["footer-content"]
            footerRef.querySelectorAll('.footer-accordion__item--active').forEach((a) => {
                a.classList.remove('footer-accordion__item--active')
            })
        },
        setActiveLinks() {
            const footerRef = this.$refs["footer-content"]
            const windowPathname = new URL(window.location.href).pathname
            footerRef.querySelectorAll('a.footer-menu__link').forEach((a) => {
                const linkPathname = a.getAttribute('href');
                if (linkPathname === windowPathname) {
                    a.classList.add('link-active')
                } else {
                    a.classList.remove('link-active')
                }
            })
        },
        initLangWidget() {
            const footerRefContent = this.$refs["footer-content"]
            const langRefComponent = this.$refs['language'];

            if(footerRefContent && langRefComponent) {
                footerRefContent.querySelectorAll('template.widget-language').forEach(w => {
                    w.replaceWith(langRefComponent.$el)
                })
            }

            // if (this.smAndDown && this.mobileFooter) {
            //     const footerAppend = this.$refs["footer-append"]
            //     const copyright = footerRef.querySelector('.footer-row-countries')
            //     footerAppend.innerHTML = ''
            //     footerAppend.appendChild(copyright)
            // }
        }
    },
    watch: {
        isMobile() {
            this.footerLoad()
        },
        desktopFooter() {
            this.footerLoad()
        },
        $route(to, from) {
            if (to !== from) {
                this.setActiveLinks()
                this.removeActiveClass()
            }
        },
    },
    computed: {
        ...mapState({
            desktopFooter:
                state => state.app.controlContents.hasOwnProperty('desktopFooter') ? state.app.controlContents.desktopFooter.content : '',
            mobileFooter:
                state => state.app.controlContents.hasOwnProperty('mobileFooter') ? state.app.controlContents.mobileFooter.content : '',
            currentLang:
                state => state.user.current_lang,
        }),
        isMobile() {
            return this.smAndDown
        }
    },
    mounted() {
        const footerRef = this.$refs["footer-content"]
        footerRef.addEventListener('click', this.handle)
    },
    updated() {
        this.setActiveLinks()
        this.initLangWidget()
    }
}
</script>

<style lang="scss">

.footer {
    border-top: 4px solid var(--v-primary-base) !important;

    & > .footer-lang-component {
        display: none !important;
    }

    hr.border-3 {
        border: none;
        border-bottom: 3px solid var(--v-primary-base) !important;
        margin: 0 -30px;
    }
}

.footer-column {
    display: flex;
    margin-bottom: 2.2em;
    padding: 30px;
    justify-content: space-between;
}

.footer-row-logo {
    @media screen and (max-width: 960px) {
        display: flex;
        justify-content: center;
    }
}

.footer-logo__social-items {
    @media screen and (max-width: 960px) {
        display: flex;
        justify-content: center;
    }
}

.footer-row-menu {
    display: flex;

    //@media screen and (min-width: 1250px) {
    //    margin-left: -80px;
    //}

    padding-top: 10px;

    .footer-row-menu__section {
        width: 180px;
    }

    .footer-row-menu__links {
        width: 200px;
        padding: initial;
        list-style: none;
        //font-family: Inter;
        letter-spacing: 0;
        text-align: left;
        color: #FFFFFF;
    }

    //font-family: Inter;
    //font-size: 15px;
    //font-weight: 800;
    //line-height: 12px;
    //letter-spacing: 0em;
    //text-align: left;

    .footer-row-menu__links li {
        //font-family: Inter;
        font-size: 13px;
        font-weight: 700;
        //line-height: 1.5;
        //letter-spacing: 0em;
        text-align: left;

        line-height: 25px;
        margin: 6px 0;
    }

    .footer-row-menu__links li a {
        color: #8d8d8d !important;
        font-size: 14px;
        font-weight: 400;
    }

    .footer-row-menu__links-header {
        font-size: 16px !important;
        font-weight: 600 !important;
        margin-bottom: 2px !important;
        color: #fff;
    }
}

.footer-row-copyright {
    flex-direction: column;
    display: flex;

    .footer-row-copyright__title {
        width: 407px;
        padding-bottom: 10px;
        //font-family: Inter;
        font-size: 11px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0;
        text-align: left;
    }

    .footer-row-copyright__link {
        color: #fff;
        text-decoration: underline;
    }

    .footer-row-copyright__logo {
        width: 154px;
        height: 50.45px;
    }
}

.footer-logo__wrapper {
    flex-shrink: 10;
    overflow: hidden;

    .footer-logo__image {
        width: 194px;
        height: 47px;
    }

    .footer-logo__social-items {
        display: flex;
        align-items: center;
    }

    .footer-logo__social-item {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}

.footer-bg-gray {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--v-gray-3-base);
}

.width-100 {
    width: 100%;
}

.footer__mobile {
    padding: 15px 30px 15px 30px !important;

    .footer-column {
        display: flex;
        margin-bottom: 0;
        padding: 0;
    }

    .footer-logo__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-logo__image {
        display: block;
        padding-right: 13px;
        margin-bottom: 16px;
        width: auto;
        height: 40px !important;
        max-width: 185px;
    }

    .footer-logo__social-items {
        display: flex;
        padding: 7px;
        align-items: center;
    }

    .footer-row-countries {
        display: flex;
        margin-top: 7px;
        margin-bottom: 16px;

        .footer-row-countries__item {
            display: flex;
            align-items: center;
            margin-right: 7px;
        }
    }

    .footer-row-menu {
        display: flex;

        @media screen and (max-width: 400px) {
            flex-wrap: wrap;
        }

        .footer-row-menu__links {
            width: 100px;
            padding: initial;
            list-style: none;
            //font-family: Inter;
            letter-spacing: 0;
            text-align: left;
            color: #FFFFFF;
        }

        .footer-row-menu__links li,
        .footer-row-menu__links li a{
            font-weight: 700;
            font-size: 8px;
            line-height: 2.6;
        }

        .footer-row-menu__links li a {
            color: #FFFFFF !important;
        }
    }

    .footer-row-copyright {
        display: flex;
        flex-direction: unset;
        align-items: center;

        .footer-row-copyright__title {
            padding-left: 20px;
            //font-family: Inter;
            font-size: 7px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0;
            text-align: left;
        }

        .footer-row-copyright__link {
            color: #fff;
            text-decoration: underline;
        }
    }

    .footer__wrapper {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .footer-contact__wrapper {
        display: flex;
        padding: 1px 10px 15px 15px;
        align-items: center;


        .footer-contact__button {
            padding: 10px 20px 10px 20px;
            border-radius: 4px;
            font-weight: bolder !important;
            background-color: var(--v-primary-base) !important;
            color: var(--v-background-base) !important;
            caret-color: var(--v-background-base) !important;
        }
    }

    .footer-bg-gray {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--v-gray-3-base);
    }

    .width-100 {
        width: 100%;
    }
}

.footer-slider {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0 auto;

    //@media screen and (max-width: 1025px) {
    //    border-top: 1px #131C29 solid;
    //}
    //
    //&:before, &:after {
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    bottom: 0;
    //    z-index: 3;
    //    width: 70px;
    //    visibility: visible
    //}
    //
    //&:before {
    //    left: 0;
    //    background: linear-gradient(to right, #02060e 0%, #02060e 33%, rgba(7, 12, 21, 0) 100%);
    //}
    //
    //&:after {
    //    right: -2px;
    //    background: linear-gradient(90deg, rgba(7, 12, 21, 0) 0, #02060e 62%);
    //}
}

.footer-partners {
    //align-items: center;
    //display: flex;
    //justify-content: center;
    //overflow: hidden;
    //white-space: nowrap;
    ////margin-top: 10px;
    ////margin-bottom: 10px;
    //
    //@media screen and (max-width: 530px) {
    //    margin: 0;
    //}
    //
    //&__list {
    //    display: flex;
    //    //flex-wrap: wrap;
    //    flex-wrap: nowrap;
    //    justify-content: center;
    //    align-items: center;
    //    height: inherit;
    //    filter: grayscale(100%);
    //    transition: transform .25s ease-in;
    //
    //    @media screen and (max-width: 530px) {
    //        flex-wrap: nowrap;
    //        //padding: 1em;
    //    }
    //}

    &__item {
        overflow: hidden;
        margin: 20px 5px;
        line-height: 0;
        //cursor: pointer;

        img {
            align-items: center;
            padding-left: 2em;
            height: 15px;
            cursor: pointer;
        }

        &:hover {
            img {
                animation: footer-animate .3s ease-in forwards;
            }
        }
    }
    .swiper-slide {
        width: auto !important;
    }
}
</style>
