<template>
    <div v-if="isHasBonus && isHasBonusProgressBar" class="bonus-section">
        <div
            v-if="bonus.name"
            class="bonus-title"
        >
            <v-icon class="bonus-icon" size="18">custom-gift</v-icon>
            <span>{{ bonus.name }}</span>
        </div>
        <div
            v-if="isHasBonusProgressBar"
            class="bonus-section__progerss"
        >
            <v-progress-linear
                :value="progressBarPercent"
                height="25"
                color="secondary"
                rounded
                background-opacity="0"
            >
                <strong class="bonus-progress-title">{{ progressBarPercent }}%</strong>
            </v-progress-linear>
        </div>
        <div>
            <v-btn small color="primary"
                   v-if="!disallowPlayerCancelBonus"
                   @click="confirmDialog = true">
                {{ $t("dashboard.sections.bonuses.cancelBonus") }}
            </v-btn>
            <bonus-confirm-cancel-dialog v-model="confirmDialog" />
        </div>
    </div>
</template>

<script>


import bonuses from "@/mixins/bonuses";
import BonusConfirmCancelDialog from "@/components/modals/BonusConfirmCancelDialog.vue";

export default {
    components: {BonusConfirmCancelDialog},
    mixins: [bonuses],
    data: () => ({
        confirmDialog: false,
    })
};
</script>

<style lang="scss" scoped>
.bonus-section {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 12px;
}

.bonus-title {
    margin-bottom: -3px;
    font-size: 14px;
    line-height: 18px;
    color: var(--v-secondary-base);
    text-transform: uppercase;
    font-weight: 600;

    .bonus-icon {
        margin-bottom: 7px;
        color: inherit !important;
    }

    span {
        font-size: inherit;
        line-height: inherit;
    }
}

.bonus-progress-title {
    text-shadow: 0 0 3px var(--v-background-base), 0 0 3px var(--v-background-base), 0 0 3px var(--v-background-base);
    color: var(--v-secondary-base);
}

.bonus-section__progerss {
    flex-grow: 1;
}

.v-progress-linear {
    border: 1px solid var(--v-secondary-base);
}
</style>
