import { mapState } from "vuex"
import config from "@/configs"

export default {
    data() {
        return {
            timeouts: {},
            selectedCryptoCurrency: null,
            selectedCryptoNetwork: null,
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('form.required'),
            },
        }
    },

    computed: {
        ...mapState('app', [
            'currency',
        ]),

        ...mapState({
            userCryptoBalances: state => state.user.crypto.balances,
            brandCryptoCurrencies: state => state.app.brandSettings.crypto_currencies?.split(',') || null,
            brandCurrencies: state => state.app.brandSettings.currencies?.split(',') || null,
        }),

        /**
         * List of cryptocurrencies
         * @return {*|*[]}
         */
        currenciesCrypto() { // TODO: Get from UTORG
            const configCryptoCurrencies = config.crypto_currencies.crypto_currencies

            return this.brandCryptoCurrencies
                ? configCryptoCurrencies?.filter(v => this.brandCryptoCurrencies.find(c => c === v.name))
                : []
        },

        /**
         * List of currencies
         * @return {*|*[]}
         */
        currencies() { // TODO: Get from UTORG
            const configCurrencies = config.crypto_currencies.currencies

            return this.brandCurrencies
                ? configCurrencies?.filter(v => this.brandCurrencies.find(c => c === v.name))
                : []
        },

        /**
         * Networks for selected currency
         * @return {[{text: string, value: string},{text: string, value: string}]|*[]}
         */
        networksCrypto() {
            return this.getCryptoCurrencyNetwork(this.currentCryptoCurrency)
        },

        /**
         * Default cryptocurrency
         * @return {*|null}
         */
        defaultCryptoCurrency() {
            return this.$auth.user()['cryptoBalance']?.currency
                || null
        },

        /**
         * Current cryptocurrency
         */
        currentCryptoCurrency: {
            get() {
                return this.selectedCryptoCurrency
                    || this.defaultCryptoCurrency
            },
            set(value) {
                this.selectedCryptoCurrency = value
            },
        },

        /**
         * Current cryptocurrency icon html
         * @return {*|string}
         */
        currentCryptoCurrencyIconHtml() {
            return this.currenciesCrypto.find(v => v.name === this.currentCryptoCurrency)?.icon || ""
        },

        hasNetwork() {
            return !!(this.networksCrypto?.length);
        },

        confirmationCount() {
            const confirmation = this.currenciesCrypto.find(c => c?.name === this.currentCryptoCurrency)?.confirmation;
            return Array.isArray(confirmation)
                ? confirmation.find(c => c?.network === this.selectedCryptoNetwork?.value)?.confirmation ?? 1
                : confirmation ?? 1
        }
    },

    beforeDestroy() {
        Object.keys(this.timeouts).map(key => {
            clearInterval(this.timeouts[key])
        })
    },

    methods: {
        /**
         * Get networks for crypto currency
         * @return {[{text: string, value: string},{text: string, value: string}]|*[]}
         * @param {string} currency
         */
        getCryptoCurrencyNetwork(currency) {
            switch (currency) {
                case 'ETH':
                    return [
                        {text: 'ETH - Ethereum (ERC20)', value: 'ETH'},
                        {text: 'BSC - BNB Smart Chain', value: 'BSC'},
                    ];
                case 'USDT':
                    return [
                        {text: 'ERC-20 token', value: 'ERC20'},
                        {text: 'TRC-20 token', value: 'TRC20'},
                    ];
                case 'USDC':
                    return [
                        {text: 'ERC-20 token', value: 'ERC20'},
                        {text: 'TRC-20 token', value: 'TRC20'},
                    ];
                default:
                    return [];
            }
        },

        /**
         * Generate formatted crypto in fiat string
         * @param currencyName
         * @return {string}
         */
        amountCryptoAsFiatString(currencyName) {
            const fiatAmount = parseFloat(this.userCryptoBalances?.find(i => i.name === currencyName)?.amount || 0)

            let resultString = ''
            resultString += 'left' === this.currency.currencySymbolPosition ? this.currency.currencySymbol : ''
            resultString += fiatAmount.toFixed(this.currency.decimalDigits)
            resultString += 'left' !== this.currency.currencySymbolPosition ? this.currency.currencySymbol : ''

            return resultString
        },

        /**
         * Get crypto icon html
         * @param name
         * @param fiat
         * @return {*|string}
         */
        getIconHtml(name, fiat = false) {
            const currency = fiat
                ? this.currencies.find(v => v.name === name)
                : this.currenciesCrypto.find(v => v.name === name)
            return currency ? currency.icon : ""
        },

        /**
         * Copy to clipboard with timeout
         * @param array
         * @param key
         */
        toClipboard(array, key) {
            this.timeouts[key] = setTimeout(
                () => {
                    clearTimeout(this.timeouts[key])
                    this.timeouts[key] = null
                },
                3000
            )

            navigator
                .clipboard
                .writeText(array[key])
                .catch(console.error)
        },
    },
}
