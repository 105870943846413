import { render, staticRenderFns } from "./ProfileDrawer.vue?vue&type=template&id=0363aa2c"
import script from "./ProfileDrawer.vue?vue&type=script&lang=js"
export * from "./ProfileDrawer.vue?vue&type=script&lang=js"
import style0 from "./ProfileDrawer.vue?vue&type=style&index=0&id=0363aa2c&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runcloud/webapps/demo-2-jetxplatform/releases/20241114081113/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0363aa2c')) {
      api.createRecord('0363aa2c', component.options)
    } else {
      api.reload('0363aa2c', component.options)
    }
    module.hot.accept("./ProfileDrawer.vue?vue&type=template&id=0363aa2c", function () {
      api.rerender('0363aa2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/navigation/ProfileDrawer.vue"
export default component.exports