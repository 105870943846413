import configs from '../../../configs'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const {product, time, theme, currencies} = configs

const {globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL} = theme
const {currency, availableCurrencies} = currencies

// state initial values
const state = {
    windows: {
        login: false,
        register: false,
        forgot: false,
        wallet: false,
        verifyEmail: false,
        verifyEmailBeforeLogin: false,
        verifyEmailSuccess: false,
        betTimeout: false,
        userDashboardMobile: false,
        error: false,
    },
    profileDrawer: false,
    preloader: {
        active: true,
        opacity: 1
    },
    errorMessage: '',
    userDashboardMobileDialog: {component: null, title:'', route: null},
    redirectOnAuth: '',

    platform: 'desktop',

    product,

    time,

    // currency
    currency,
    availableCurrencies,

    // themes and layout configurations
    globalTheme,
    menuTheme,
    toolbarTheme,
    isToolbarDetached,
    isContentBoxed,
    isRTL,

    layout: {
        footer: true,
        header: true,
        bottomBar: true
    },

    banners: {},
    country: '',
    gameLinkBeforeLogin: '',

    brandSettings: {},
    tags: [],
    tagEvent: 0,
    scripts: {},
    top_winners_list: [],
    brandSkin: {},

    controlContents: {},

    translationSettings: [],

    userBonuses: [],

    loadedGame: {},
    bonusUpdateKey: Math.random().toString(16).substr(2, 10),
    providersSort: null,

    // App.vue main toast
    toast: {
        show: false,
        color: 'black',
        message: '',
        timeout: 3000
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
