export default {
  // global theme for the app
  globalTheme: 'dark', // light | dark

  // side menu theme, use global theme or custom
  menuTheme: 'dark', // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: 'dark', // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    background: '#111', //'#202123',
      'gray-1': '#E5E7EC',
      'gray-2': '#D7DAE0',
      'gray-3': '#292B2E',
      'text': '#fff',
    black: '#202123',
    surface: '#292B2E',
    primary: '#4f8256', //'#58825C',
    secondary: '#fbb144',
    accent: '#048ba8',
    // error: '#ef476f',
    error: '#dd0d00',
    info: '#2196F3',
    success: '#06d6a0',
    warning: '#ffd166',
    header: '#15201c',
    'header-extension': '#3e8451',
    'header-tab-active': '#14201b',
    'slots-title': '#ffb341',
    'cancel': '#e2594c',
    'dialog-background': '#1c3011',
    'dialog-button': '#ffb341',
    'dialog-button-text': '#202123',
    'dialog-text': '#fff',
  },

  // light theme colors
  light: {
    background: '#fff',
    surface: '#042E39',
    primary: '#4f8256',
    secondary: '#18181A',
    accent: '#048ba8',
    error: '#ef476f',
    info: '#2196F3',
    success: '#06d6a0',
    warning: '#ffd166',
    'auth-bar': '#1d2128'
  }
}
