import Vue from 'vue'

export default {
    // params ({state, getters, rootGetters})
    currencies(state) {
        return state.brandSettings.currencies ? state.brandSettings.currencies.replace(/\s/g, '').split(',') : [];
    },
    currency(state, getters) {
        return Vue.auth.user() ? Vue.auth.user().currency : (getters.currencies[0] ?? '');
    },
    countries: state => state.brandSettings?.countries ?? [],
    country: state => state.country,
    google2fa_enable: state => state.brandSettings.google2fa,
    google2fa_required: state => state.brandSettings.google2fa_required,
    redirect_after_login: state => state.brandSettings.redirect_after_login,
    translationSettings: state => state.translationSettings,
}
