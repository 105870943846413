<template>
    <v-dialog
        v-model="isActive"
        max-width="600"
        dark
    >
        <template v-slot:activator="props">
            <slot name="activator" v-bind="props" />
        </template>

        <v-card class="pb-2">
            <v-card-title class="headline">
                {{ $t("dashboard.sections.bonuses.freespinDetails") }}
            </v-card-title>

            <v-card-text class="d-flex align-center mb-2">
                <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.freespinsAmount') }}:</div>
                <div class="text-subtitle-1 font-weight-bold ml-2">
                    {{ bonus.settings.freespin_quantity }}
                </div>
            </v-card-text>

            <template v-if="request.isLoading">
                <v-progress-circular
                    indeterminate
                    size="36"
                />
            </template>
            <template v-else>
                <template v-if="request.isError">
                    Error
                </template>
                <template v-else>
                    <v-card-text v-if="request.data.providers && request.data.providers.length">
                        <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.allowedGameProviders') }}:</div>
                        <span v-for="provider in request.data.providers" :key="provider.id">
                        <router-link
                            class="text-subtitle-1 font-weight-bold"
                            :to="`/casino/search?providers_id=${provider.id}`"
                            @click.native="closeModal"
                        >
                            {{ provider.original_name }}
                        </router-link>
                    </span>
                    </v-card-text>

                    <v-card-text v-if="request.data.games && request.data.games.length">
                        <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.allowedGames') }}:</div>
                        <div class="d-flex flex-wrap justify-space-between justify-lg-start">
                            <router-link
                                v-for="game in request.data.games" :key="game.id"
                                class="freespin-game"
                                :to="'/casino/' + (game.game_provider ? game.game_provider.slug : 'provider') + '/' + (game.launch_code || game.slug)"
                                @click.native="closeModal"
                            >
                                <v-img
                                    max-width="150"
                                    class="rounded elevation-1"
                                    aspect-ratio="1"
                                    :src="getImage(game, 'public', 200)"
                                    :lazy-src="getImage(game, '250x250', 50)"
                                    position="center top">
                                </v-img>
                            </router-link>
                        </div>
                    </v-card-text>

                    <v-card-text v-if="request.data.categories && request.data.categories.length">
                        <div class="text-subtitle-1 mb-2">
                            {{ $t("dashboard.sections.bonuses.allowedCategories") }}:
                        </div>

                        <span v-for="category in request.data.categories" :key="category.id">
                            <router-link
                                class="text-subtitle-1 font-weight-bold"
                                :to="getCategoryUrl(category)"
                                @click.native="closeModal"
                            >
                                {{ category.name[current_lang] }}
                            </router-link>
                        </span>
                    </v-card-text>

                    <v-card-text>
                        <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.turnoverRequirements') }}:
                            {{ bonus.settings.rollover_requirement }}x
                            {{ $t('dashboard.sections.bonuses.turnoverRequirementsText') }}
                        </div>
                    </v-card-text>
                </template>
            </template>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tile-gradient-dark"
                    text
                    @click="isActive = false"
                >
                    {{ $t("common.close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import breaks from "@/mixins/breaks";
import bonuses from "@/mixins/bonuses";
import RequestObject from "@/helpers/RequestObject";
import { mapState } from "vuex"

export default {
    name: 'BonusModalFreespin',
    mixins: [breaks, bonuses],
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data: () => {
        return {
            request: null,
        }
    },
    computed: {
        ...mapState({
            current_lang: (state) => state.user.current_lang
        }),
        isActive: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val);
            }
        },
    },
    created() {
        this.request = new RequestObject(this.getFreespinInfo, this.bonus);
        this.request.fetch();
    },
    methods: {
        closeModal() {
            this.isActive = false
        },

        getCategoryUrl(category) {
            let page = category?.is_live ?
                'live-casino'
                : category?.is_virtual_sport
                    ? 'virtual-sports'
                    : 'casino';

            return `/${page}/category/${category?.url}`;
        }
    },

}
</script>
